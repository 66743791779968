//
// Colors
//
$white: #ffffff;
$black-grey: #555555;
$black: #333333;
$blackest: #000000;
$fonda-blue: #2980b9;
$blue-07: rgba(41, 128, 185, 0.41);
//$primary-color: #888888;
$fonda-blue-hover: #236fa1;
//$primary-color-hover: #333333;
$red: #e4254e;
$red-hover: #c92045;
$green: #add5ae;
$green-hover: #99bd99;
$content: #888888;
$placeholder: #999999;
$border: #cccccc;
$alt-border: #f2f2f2;
$inactive: #777777;
$lightgray: #e8ebed;
$alt-white: #f3f6f9;
$list-bg: #e8ecf2;
$table-title: #e6e6e6;

$tangerine: #f9812a;
$light-orange: #fecba3;

$green-darker: #5cae5c;
$green-darkest: #3c7c3c;
$light-green: #a2d0a2;
$text-editor-toolbar-color: #f1f1f1;

$primary-color: $fonda-blue;
$primary-color-hover: $fonda-blue-hover;
$applicant-primary-color: $content;
$applicant-primary-color-hover: $black;

//
// PWA Colors
//

$pwa-white: #ffffff;
$pwa-dark: #000000;
$pwa-upload: #f6f6f6;
$pwa-border: #e3eaf1;
$pwa-background: #f2f5f8;
$pwa-message: #dee6f8;
$pwa-placeholder: #bcbcbc;
$pwa-message-sender: #2c374b80;
$pwa-document: #2c374b;
$pwa-footer: #1c1c1c;
$pwa-error: #ff6262; // $pwa-red on $pwa-white with 0.62 opacity

$pwa-orange: #ed7d30;
$pwa-orange-hover: #e45000;
$pwa-blue: #002bff;
$pwa-blue-hover: #0008cc;
$pwa-green: #08d43f;
$pwa-green-hover: #05a10c;
$pwa-green-light: #d8f2e4;
$pwa-gray: #f2f5f8;
$pwa-gray-hover: #e3e6e7;
$pwa-red: #ff0303;
$pwa-red-hover: #cc0101;
$pwa-pink: #ffe4e4;
$pwa-pink-hover: #eec1c1;
