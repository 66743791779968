//
// Administration tools
//
.admin-application-container {
    @include flex(space-between);
    overflow-y: hidden;
}

.admin-application-tools {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    //-webkit-box-shadow: 2px 0 10px -5px #000;
    //box-shadow: 2px 0 10px -5px #000;
    &.hidden {
        visibility: hidden;
        opacity: 0;
    }

    &.not-displayed {
        display: none;
    }

    .application-flow {
        h2 {
            padding: 0;
        }
        > h2 {
            font-size: 1.4em;
            padding: 20px;
        }

        .records {
            a {
                &:not(.icon) {
                    width: 100%;
                    max-width: calc(50% - 15px);
                    height: 95px;
                    &.full {
                        max-width: 100%;
                        height: initial;
                    }
                }

                p {
                    font-size: 0.8em;
                }

                span:not(.icon-close) {
                    font-size: 1em;
                    color: $black;
                    display: block;
                    width: 100%;
                }
            }
        }

        .caseworker-container {
            padding: 25px 30px 25px 30px;
        }

        form {
            > div {
                font-size: inherit;
            }
        }

        ul {
            &.files {
                background-color: $white;
                margin: 0 0 50px;
                @include shadow(0.3);

                li {
                    padding-top: 0;

                    ul {
                        cursor: pointer;
                        border-bottom: 1px solid $border;
                        @include transition(0.5s);

                        &.header {
                            border-bottom: none;
                            cursor: default;

                            li {
                                color: $black;
                            }
                        }

                        li {
                            display: inline-block;
                            padding: 15px 0;
                        }

                        &:hover {
                            background-color: $primary-color;

                            &.header {
                                background-color: transparent;

                                li {
                                    color: $black;
                                }
                            }

                            li {
                                color: $white;
                            }
                        }
                    }
                }

                &.readonly {
                    margin: 0;
                    width: 100%;
                    ul {
                        @include flex(flex-start);
                        li {
                            &:nth-child(1) {
                                max-width: 65%;
                                width: 65%;
                                padding-left: 15px;
                            }

                            &:nth-child(2) {
                                width: 15%;
                                max-width: 20%;
                            }

                            &:nth-child(3) {
                                width: 20%;
                                max-width: 20%;
                                text-align: right;
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
            &.readonly-checkbox {
                @include flex(flex-start);
                align-items: baseline;
                input[type='checkbox'] {
                    display: none;
                }

                label {
                    font-weight: 300;
                    width: 100%;
                    display: inline-block;
                    position: relative;

                    &:before {
                        content: '';
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        border-radius: 3px;
                        border: 1px solid $border;
                        background-color: $white;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        vertical-align: middle;
                        margin-right: 20px;
                        cursor: pointer;
                    }
                }

                input[type='checkbox']:checked + label:before {
                    @include svg($icon-check, $green, 18px);
                }
            }
        }

        .stable-file-container {
            margin: 30px 0 20px;
            label {
                display: inline-block;
                margin-bottom: 1rem;
            }
        }
    }

    h1 {
        margin-bottom: 20px;
    }

    h3 {
        &.subject-title {
            padding-top: 20px;
            padding-bottom: 10px;
            clear: both;
        }
    }

    a {
        &.expand-view {
            position: absolute;
            top: 0;
            right: 0;
            margin: 20px 0px 0 0;
            width: 50px;
            height: 50px;
            display: block;
            border-radius: 100%;
            border: 1px solid $border;
            @include svg($icon-expand, $black, 16px);
        }

        &:hover {
            &.expand-view {
                border-color: $primary-color;
                background-color: $primary-color;
                @include svg($icon-expand, $white, 16px);
            }
        }
    }

    form {
        h2 {
            padding: 15px;
        }
        .artwork-image {
            p {
                font-size: 0.8em;
            }

            span {
                display: block;
                width: 100%;
                font-style: italic;
            }
        }

        .dialogue {
            @include dialogue;
        }

        .texting {
            margin: 15px 0;
            width: 100%;
            vertical-align: top;
            padding: 0 15px;
            &.popup {
                padding: 0;
            }

            .cke_contents {
                height: 100px !important;
            }
            .actions {
                button {
                    &.button {
                        margin-top: 15px;
                        position: relative;
                        top: 0;

                        &:disabled {
                            background-color: $inactive;
                            &:hover {
                                background-color: $inactive;
                                color: $black;
                            }
                        }
                    }
                }
            }
            > div {
                label {
                    top: 0;
                }
            }

            textarea {
                width: 100%;
                height: 100px;
                border: 1px solid $border;
                background-color: $alt-border;
                margin-right: 15px;
                padding: 15px;
                line-height: 1.5;
                outline: none;
                resize: none;

                &::-webkit-input-placeholder,
                &::-ms-input-placeholder,
                &:-ms-input-placeholder {
                    color: $content;
                }
            }

            a {
                margin-top: 0;

                &.icon-image,
                &.icon-attachment {
                    width: 16px;
                    height: 16px;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    display: inline-block;
                }

                &.icon-image {
                    margin-left: 15px;
                    @include svg($icon-image, $content, 16px);
                }

                &.icon-attachment {
                    margin-left: 10px;
                    @include svg($icon-attachment, $content, 16px);
                }

                &:hover {
                    &.icon-image {
                        @include svg($icon-image, $primary-color, 16px);
                    }

                    &.icon-attachment {
                        @include svg($icon-attachment, $primary-color, 16px);
                    }
                }
            }
        }

        a {
            margin-top: 20px;
            display: inline-block;
            &:not(.button) {
                margin-top: 0;
            }
        }
    }
    .history-log {
        width: 100%;
        display: table;

        > div {
            padding: 20px 30px;
            border-bottom: 1px solid $border;
            background-color: $alt-border;
            cursor: pointer;
            @include flex(space-between);
            @include transition(0.5s);

            &:first-child {
                border-top: 1px solid $border;
            }

            &.applicant {
                background-color: $alt-white;
            }

            p {
                font-size: 0.8em;
                width: 100%;
                text-align: left;
                &:nth-child(1) {
                    max-width: 30%;
                    padding-right: 15px;
                }

                &:nth-child(2) {
                    max-width: 58%;
                    padding-right: 15px;
                }

                &:nth-child(3) {
                    max-width: 12%;
                }

                &:last-child {
                    text-align: right;
                }
            }

            &:hover {
                background-color: $primary-color;

                p {
                    color: $white;
                }
            }
        }
    }

    .log-description {
        width: 90%;
        min-height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        background-color: white;
        @include shadow(0.3);
        @include scale(0);
        @include transition(0.5s);
        transform-origin: center;
        visibility: hidden;
        @include scalepositioned(1, 0, 0);
        top: 0;

        &.active {
            transform-origin: center;
            opacity: 1;
            visibility: visible;
            @include scalepositioned(1, -50%, 0);
            top: 0;
            left: 55%;
        }

        .container {
            .application-flow {
                .records {
                    &.full {
                        height: inherit;
                    }
                }
            }
        }
    }

    .files-table {
        @include table;
        tr {
            padding: 10px 5px;
            th {
                padding: 10px;
            }

            th,
            td {
                &:last-child {
                    width: 60px;
                }
            }
        }
    }
}
