//
// Mixins
//
@mixin center($position-x, $position-y) {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate($position-x, $position-y);
    transform: translate($position-x, $position-y);
}

@mixin centerfixed($position-x, $position-y) {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate($position-x, $position-y);
    transform: translate($position-x, $position-y);
}

@mixin flex($flex) {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: $flex;
    justify-content: $flex;
}

@mixin flex-column($column) {
    -webkit-flex-direction: $column;
    flex-direction: $column;
}

@mixin flex-align($align) {
    align-items: $align;
    -ms-flex-align: $align;
    -webkit-align-items: $align;
    -webkit-box-align: $align;
}

@mixin transition($length) {
    -webkit-transition: all $length cubic-bezier(0.24, 0.63, 0.5, 0.99);
    transition: all $length cubic-bezier(0.24, 0.63, 0.5, 0.99);
}

@mixin rotate($rotate) {
    -ms-transform: rotate($rotate);
    -webkit-transform: rotate($rotate);
    transform: rotate($rotate);
}

@mixin scale($scale) {
    -webkit-transform: scale($scale);
    -ms-transform: scale($scale);
    -moz-transform: scale($scale);
    transform: scale($scale);
}

@mixin scalepositioned($scale, $position-x, $position-y) {
    -webkit-transform: scale($scale) translate($position-x, $position-y);
    -ms-transform: scale($scale) translate($position-x, $position-y);
    -moz-transform: scale($scale) translate($position-x, $position-y);
    transform: scale($scale) translate($position-x, $position-y);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
}

@mixin scalefixed($scale, $position-x, $position-y) {
    -webkit-transform: scale($scale) translate($position-x, $position-y);
    -ms-transform: scale($scale) translate($position-x, $position-y);
    -moz-transform: scale($scale) translate($position-x, $position-y);
    transform: scale($scale) translate($position-x, $position-y);
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
}

@mixin shadow($opacity) {
    -webkit-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, $opacity);
    -moz-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, $opacity);
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, $opacity);
}

@mixin pwa-box-shadow() {
    -webkit-box-shadow: 0 3px 6px 0 rgba(175, 175, 175, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(175, 175, 175, 0.16);
    box-shadow: 0 3px 6px 0 rgba(175, 175, 175, 0.16);
}

@mixin centered-shadow($opacity) {
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, $opacity);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, $opacity);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, $opacity);
}

@mixin basicImgSettings() {
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: contain;
}

@mixin svg($svg, $color, $size) {
    background-image: inline-color-svg($svg, $color);
    background-size: $size;
    @include basicImgSettings();
}

@mixin logo($size) {
    background-size: $size;
    @include basicImgSettings();
}

@mixin gradient-overlay() {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}
@mixin label-form($color) {
    position: relative;
    display: block;
    width: 100%;

    &:before,
    &:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0px;
        position: absolute;
        z-index: 30;
        background-color: $color;
        @include transition(0.3s);
    }

    &:before {
        left: 50%;
    }

    &:after {
        right: 50%;
    }
}

@mixin breakpoint-min($min-width) {
    @media only screen and (min-width: $min-width) {
        @content;
    }
}

@mixin loader() {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

@mixin table() {
    width: 100%;
    padding: 15px 0px;

    border-collapse: collapse;

    @include shadow(0.7);
    tr {
        border-bottom: 1px solid $border;
        &:last-child,
        &:first-child {
            border: none;
        }
        th {
            padding: 10px;
            padding-top: 0;
            text-align: left;
        }

        td {
            padding: 10px 2px;
            padding-left: 10px;
            word-break: normal;

            .download-icon {
                @include transition(0.25s);
                @include svg($icon-download, $black, 25px);
                width: 40px;
                height: 40px;
                cursor: pointer;
                border: 1px solid $lightgray;
                &:hover {
                    border-color: $primary-color-hover;
                    background-color: $primary-color-hover;
                    @include svg($icon-download, $white, 25px);
                }
            }

            .log-icon {
                @include transition(0.25s);
                @include svg($icon-log, $black, 26px);
                width: 30px;
                height: 30px;
                display: inline-block;
                cursor: pointer;

                &:hover {
                    //border-color: $primary-color-hover;
                    //background-color: $primary-color-hover;
                    @include svg($icon-log, $primary-color-hover, 26px);
                }
            }

            .delete-icon {
                @include transition(0.25s);
                @include svg($icon-cross, $red, 25px);
                width: 25px;
                height: 40px;
                cursor: pointer;
                &:hover {
                    @include svg($icon-cross, $red-hover, 25px);
                }
            }

            .edit-icon {
                border: 1px solid $border;
                border-radius: 3px;
                @include transition(0.25s);
                @include svg($icon-pen, $black, 15px);
                width: 30px;
                height: 30px;
                display: inline-block;
                cursor: pointer;

                &:hover {
                    border-color: $primary-color-hover;
                    background-color: $primary-color-hover;
                    @include svg($icon-pen, $white, 15px);
                }
            }

            .trash-icon {
                border: 1px solid $border;
                border-radius: 3px;
                @include transition(0.5s);
                @include svg($icon-trash, $black, 15px);
                width: 30px;
                height: 30px;
                display: inline-block;
                cursor: pointer;

                &:hover {
                    border-color: $primary-color-hover;
                    background-color: $primary-color-hover;
                    @include svg($icon-trash, $white, 15px);
                }
            }

            .approve-icon {
                border: 1px solid $border;
                border-radius: 3px;
                @include transition(0.25s);
                @include svg($icon-approve, $black, 15px);
                width: 30px;
                height: 30px;
                display: inline-block;
                cursor: pointer;

                &:hover {
                    border-color: $primary-color-hover;
                    background-color: $primary-color-hover;
                    @include svg($icon-approve, $white, 15px);
                }
            }

            .document-icon {
                border: 1px solid $border;
                border-radius: 3px;
                @include transition(0.25s);
                @include svg($icon-document, $black, 15px);
                width: 30px;
                height: 30px;
                display: inline-block;
                cursor: pointer;

                &:hover {
                    border-color: $primary-color-hover;
                    background-color: $primary-color-hover;
                    @include svg($icon-document, $white, 15px);
                }
            }

            .cancel-icon {
                border: 1px solid $border;
                border-radius: 3px;
                @include transition(0.25s);
                @include svg($icon-cross, $black, 15px);
                width: 30px;
                height: 30px;
                display: inline-block;
                cursor: pointer;

                &:hover {
                    border-color: $primary-color-hover;
                    background-color: $primary-color-hover;
                    @include svg($icon-cross, $white, 15px);
                }
            }

            .file-name {
                max-width: 250px;
                word-break: break-word;
            }

            .expand-icon {
                @include transition(0.5s);
                @include svg($icon-arrow-down, $primary-color, 25px);
                width: 25px;
                height: 25px;
                display: inline-block;
                &.active {
                    @include rotate(180deg);
                }
            }
        }

        &:not(:first-child) {
        }
    }

    @include breakpoint-min(1280px) {
        padding: 10px 10px;
    }
}
