//
// Hero
//
.hero {
    position: relative;
    width: 100%;
    height: calc(100vh + 100px);
    min-height: 510px;
    max-height: 800px;
    background-color: $green;
    background-color: var(--hero-background-color);
    background-size: cover;
    overflow: hidden;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        //background-color: rgba(0,0,0,0.5);
        position: absolute;
        top: 0;
        left: 0;
    }

    .hero-content {
        width: calc(100% - 40px);
        max-width: 800px;
        @include center(-50%, 0);
        top: 0;
        margin-top: 190px;

        h1,
        p {
            text-align: center;
            color: $black;
            color: var(--hero-color);
        }

        h1 {
            font-size: 2em;
            font-weight: 700;
            margin-bottom: 20px;
        }

        p {
            //padding: 30px 0 20px;
        }

        .form-container {
            margin: auto;
            max-width: 450px;
            @include flex(center);
            flex-flow: row wrap;
            button {
                cursor: pointer;
                width: 100%;
                margin: 20px 0 0 0;
                padding: 15px 0;
                font-size: 1.5rem;
                color: $black;
                color: var(--hero-color);
                border: 1px solid $black;
                border-color: var(--hero-color);
                background-color: transparent;
                &:hover {
                    background-color: $green-hover;
                    background-color: var(--hero-background-color-hover);
                    border-color: var(--hero-color-hover);
                    color: var(--hero-color-hover);
                }
            }

            @include breakpoint-min(450px) {
                @include flex(space-between);
                button {
                    width: 49%;
                    margin: 0;
                }
            }
        }

        #create-account {
            .form-item {
                &.half {
                    width: 100%;

                    &.half-last {
                        margin-left: 0;
                    }
                }

                label {
                    top: -4px;
                }

                input {
                    color: $white;

                    &:focus ~ label,
                    &:not(:placeholder-shown) ~ label {
                        color: $white;
                        top: -20px;
                    }
                    padding: 0;
                }

                label {
                    color: $white;
                }
            }
        }
        .icon-user-created {
            margin: 30px auto 0;
            width: 200px;
            height: 200px;
            @include svg($icon-check-circled, $black, 200px);
        }

        .icon-check-email {
            margin: 30px auto 0;
            width: 180px;
            height: 120px;
            @include svg($icon-mail-2, $black, 180px);
        }
    }
}

@media screen and (min-width: 768px) {
    .hero {
        min-height: 380px;
        height: calc(80vh - 130px);
        margin-top: 130px;
        .hero-content {
            margin-top: 0;
            @include center(-50%, -50%);
            &.basic {
                @include center(0, 0);
                left: 8%;
                top: inherit;
                right: inherit;
                bottom: 15%;
                text-align: left;
                h1 {
                    text-align: left;
                    margin-bottom: 40px;
                }
            }
            width: calc(100% - 60px);

            h1 {
                font-size: 2.5rem;
                p {
                    font-weight: inherit;
                    text-align: inherit;
                    line-height: inherit;
                }
            }
            .form-container {
                margin: 0;
            }

            #create-account {
                .form-item {
                    &.half {
                        width: calc(50% - 15px);

                        &.half-last {
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .hero {
        height: calc(80vh - 130px);
    }
}
