div.tooltip {
    @include shadow(0.5);
    background-color: $white;
    color: $black;
    position: absolute;
    padding: 0.75rem;
    font-size: 14px;
    border-radius: 8px;
    white-space: nowrap;
    z-index: 1000;
}
