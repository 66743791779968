//
// Application menu
//

.application-second-tab {
    position: relative;
    .close-btn {
        //top: 20px;
        z-index: 30;
    }
    .application-header {
        padding: 25px 30px 25px 30px;
        @include flex(space-between);
        align-items: center;

        .close-btn {
            top: 0;
            right: 0;
            position: relative;
            z-index: 0;
        }

        .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
            background-color: $primary-color;
        }
        .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
            background-color: $primary-color-hover;
        }

        .actions {
            @include flex(space-between);
            ul {
                display: flex;
                li {
                    > a,
                    .select-component {
                        margin-left: 10px;
                    }
                    display: inline-block;
                }
            }

            .close-btn {
                width: 38px;
                height: 38px;
                .icon-close {
                    background-size: 15px;
                }
            }
            a {
                &.icon-refresh {
                    width: 38px;
                    height: 38px;
                    display: inline-block;
                    .refresh {
                        background-size: 15px;
                    }
                }
            }
        }

        border-bottom: 1px solid $border;

        &.small {
            padding: 10px 10px 10px 30px;
        }
    }

    .select-component {
        .option-component-container {
            top: 38px;
        }
    }
}
section {
    &.application-review-wrapper {
        display: block;
        .container {
            width: 100%;
        }
    }
}
.application-menu {
    background-color: $white;
    width: 100%;
    overflow: auto;
    .application-details {
        display: none;

        h3 {
            padding: 15px 0 5px;
            margin-bottom: 0.5rem;
        }

        p {
            line-height: 1.5;
            span {
                color: $black;
                vertical-align: baseline;
            }
        }

        a {
            margin: 10px 0 20px;
        }

        #application-kategori {
            margin-bottom: 5px;
        }

        #application-title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            line-height: 1.2em;
            max-height: 2.5em;
            -webkit-line-clamp: 2;
            margin-bottom: 10px;
        }

        .redirect-to-previous {
            cursor: pointer;
            @include flex(flex-start);
            align-items: center;
            a {
                margin: 0 10px 0 0;
            }
        }

        select-component {
            display: block;
            width: 100%;
            margin: 20px auto 10px auto;
            .select-component {
                width: 100%;
                .option-component-container {
                    max-width: 100%;
                    width: 100%;
                    top: 37px;
                    left: 0;
                }
            }
        }
    }
    nav {
        ul {
            li {
                width: 50%;
                display: block;
                float: left;

                a {
                    width: 100%;
                    padding: 20px 0;
                    text-align: center;
                    display: block;
                    border-bottom: 1px solid $border;

                    &.active {
                        background-color: $primary-color;
                        color: $white;
                    }
                }

                ul {
                    display: none;
                }
            }
        }
    }
    .notifications-container {
        @include flex(flex-end);
        @include flex-align(center);
        .notifications-count {
            background-color: $red;
            position: relative;
            left: 0;
            right: 0;
            top: 0;
            width: 21px;
            &--yellow {
                background-color: $tangerine;
            }
            &:nth-child(2) {
                margin-left: 7px;
            }
        }
    }

    .expand {
    }
}

@media screen and (min-width: 768px) {
    .application-second-tab {
        margin-left: 320px;
        .close-btn {
            //top: 14px;
        }
        &.caseworker {
            margin-left: 280px;
        }
    }

    section {
        &.application-review-wrapper {
            display: table;
            .container {
                width: 100%;
            }

            &.caseworker {
                .application-second-tab {
                    .container {
                        padding: 20px 60px;
                    }
                }
            }
        }
    }

    .application-menu {
        width: 320px;
        height: calc(100vh - 70px);
        border-right: 1px solid $border;
        float: left;
        position: fixed;
        display: block;
        .container {
            width: calc(100% - 60px);
        }

        .application-details {
            display: block;
            padding: 20px;
        }

        nav {
            ul {
                li {
                    width: auto;
                    float: none;

                    a {
                        text-align: left;
                        padding: 15px 30px;
                        display: block;
                        border-bottom: none;

                        &.active {
                            background-color: $primary-color;
                            color: $white;
                            border-top: none;

                            &:hover {
                                background-color: $primary-color;
                                color: $white;
                            }
                        }

                        &:hover {
                            background-color: $alt-border;
                            color: $black;
                        }
                    }

                    ul {
                        @include transition(0.5s);

                        display: block;
                        li {
                            &:first-child {
                                a {
                                    border-top: none;
                                }
                            }

                            a {
                                padding-left: 60px;
                                position: relative;

                                &:before {
                                    content: '';
                                    height: 1px;
                                    width: 15px;
                                    background-color: $border;
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    margin-left: 30px;
                                    margin-top: 27px;
                                }
                            }
                        }
                    }

                    &.active {
                        ul {
                            display: block;
                        }
                    }
                }
            }

            > ul {
                > li {
                    border-top: 1px solid $border;
                    &:first-child {
                        border-top: none;
                    }
                }
            }
        }

        &.applicant-viewer {
            position: absolute;
            &.fixed-menu {
                padding-top: 0;
                top: 70px;
                position: fixed;
            }
        }
    }
}

@media screen and (min-width: 1900px) {
    .container {
        width: calc(100% - 60px);
    }
}

.applicant {
    .application-second-tab {
        .application-header {
            .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
                background-color: $primary-color;
            }

            .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
                background-color: $primary-color-hover;
            }
        }
    }

    .application-menu {
        nav {
            ul {
                li {
                    a {
                        &.active {
                            background-color: $applicant-primary-color-hover;
                            &:hover {
                                background-color: $applicant-primary-color-hover;
                            }
                        }

                        @include breakpoint-min(768px) {
                            &.active {
                                background-color: $applicant-primary-color-hover;
                                &:hover {
                                    background-color: $applicant-primary-color-hover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
