//
// Administration application review
//
.admin-application-review {
    width: 100%;
    padding: 25px 5px 50px;

    display: block;

    .caseworker-container {
        padding: 25px 30px 25px 30px;
    }

    &.hidden {
        display: none;
    }

    .application-flow {
        margin-top: 110px;
        &:nth-of-type(1) {
            margin-top: 70px;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 30px;
        }

        h2 {
            font-size: 2rem;
        }
        a.form-item,
        li {
            display: inline-block;
            width: 100%;
            cursor: auto;
            padding-top: 20px;

            &.full {
                max-width: 100%;
                &.file {
                    > ul {
                        margin-top: 0;
                    }
                    > h3 {
                        margin-top: 30px;
                    }
                }
            }

            &.economy {
                max-width: 100%;
                margin-top: 20px;

                &:first-child {
                    margin-top: 0;
                }
            }

            &.amount {
                max-width: 100%;
                padding-bottom: 5px;
                padding-top: 6px;
                height: 36px;
                border-bottom: 1px solid $border;

                &:first-child {
                    margin-top: 20px;
                }

                p {
                    font-size: 1em;
                    position: absolute;
                }

                span {
                    display: inline-block;
                    text-align: right;
                }
            }

            p {
                //font-size: 0.8em;
            }

            span:not(.icon-close):not(.mandatory) {
                font-size: 1em;
                color: $black;
                display: block;
                width: 100%;
            }

            span:not(.icon-close) {
                &.valuta {
                    &:after {
                        content: var(--currency);
                        padding-left: 5px;
                        color: $content;
                    }
                }
            }

            @include breakpoint-min(768px) {
                max-width: 49%;
            }
        }

        ul {
            flex-wrap: wrap;
            @include flex(space-between);

            &.readonly-checkbox {
                @include flex(flex-start);
                align-items: baseline;
                input[type='checkbox'] {
                    display: none;
                }

                label {
                    font-weight: 300;
                    width: 100%;
                    display: inline-block;

                    &:before {
                        content: '';
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        border-radius: 3px;
                        border: 1px solid $border;
                        background-color: $white;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        vertical-align: middle;
                        margin-right: 20px;
                        cursor: pointer;
                    }
                }

                input[type='checkbox']:checked + label:before {
                    @include svg($icon-check, $green, 18px);
                }
            }

            &.files {
                background-color: $white;
                margin: 30px 0 20px;
                @include shadow(0.3);

                li {
                    padding-top: 0;

                    ul {
                        cursor: pointer;
                        border-bottom: 1px solid $border;
                        @include transition(0.5s);

                        &.header {
                            border-bottom: none;
                            cursor: default;

                            li {
                                color: $black;
                            }
                        }

                        li {
                            padding: 15px 0;

                            &:nth-child(1) {
                                max-width: 65%;
                                padding-left: 15px;
                            }

                            &:nth-child(2) {
                                max-width: 20%;
                            }

                            &:nth-child(3) {
                                max-width: 15%;
                                text-align: right;
                                padding-right: 15px;
                            }
                        }

                        &:hover {
                            background-color: $primary-color;

                            &.header {
                                background-color: transparent;

                                li {
                                    color: $black;
                                }
                            }

                            li {
                                color: $white;
                            }
                        }
                    }
                }

                &.readonly {
                    margin: 0;
                    width: 100%;
                    ul {
                        li {
                            &:nth-child(2) {
                                display: none;
                            }

                            &:nth-child(3) {
                                width: auto;
                                max-width: inherit;
                            }
                        }
                    }
                }
            }
        }

        .stable-file-container {
            margin: 30px 0 20px;
            label {
                display: inline-block;
                margin-bottom: 1rem;
            }
        }

        .actions {
            @include flex(flex-start);
            button {
                cursor: pointer;
                &.close-btn {
                    background-color: transparent;
                    margin-left: 15px;
                    &:hover {
                        background-color: $red-hover;
                    }
                }
            }
        }
    }

    &.applicant {
        float: left;
        .application-flow {
            ul {
                &.files {
                    li {
                        ul {
                            cursor: pointer;
                            &:hover {
                                background-color: $applicant-primary-color;
                                &.header {
                                    cursor: auto;
                                    background-color: transparent;
                                    li {
                                        color: $black;
                                    }
                                }
                                li {
                                    color: $white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.downloading {
        float: none !important;
        padding: 0 !important;
        .container {
            padding: 0 !important;
            margin: 0 !important;
            width: auto;
        }
        .application-flow {
            margin: 0 !important;
            padding: 0 !important;
            h2 {
                padding: 0 !important;
                font-size: 3rem !important;
            }

            .download-first-header {
                margin-top: 0 !important;
            }
        }

        .form-item {
            max-width: initial !important;
            width: 100% !important;
            display: block !important;
        }

        li {
            &.form-item {
                &.readonly-checkbox {
                    display: none !important;
                }
            }
        }

        .records {
            a {
                p {
                    font-size: 0.9rem !important;
                    margin-bottom: 5px;
                }
            }

            i {
                font-size: 1rem;
            }
        }
        .granted-amount {
            table {
                @include shadow(0);
                padding: 0 !important;
                tr,
                th,
                td {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
            }
        }

        .custom-section {
            max-width: 100% !important;
        }
        .caseworker-container {
            padding: 0 !important;
        }
    }

    .switch-mode-buttons-container {
        position: relative;
        width: 100%;
        .switch-mode-buttons {
            position: absolute;
            right: 20px;
            top: 20px;
            @include flex(flex-end);

            button {
                cursor: pointer;

                &:nth-child(2) {
                    margin-left: 20px;
                    top: 0;
                }

                position: relative;
            }

            &.fixed {
                position: fixed;
                top: 90px;
                @include shadow(0);
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .admin-application-review {
        padding: 40px 0 50px;
        .application-flow {
            h2 {
                font-size: 3rem;
                margin: 20px 0px;
            }
        }

        &.applicant {
            padding: 0 0 50px;
            float: left;
            .container {
                padding: 50px;
            }
        }
    }
}
