//
// Formula
//
form,
.cdk-drag-preview {
    font-size: 0;
    div {
        font-size: 1rem;
    }
    &.form-center {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }

    //
    // Input + Label
    //
    .form-item {
        vertical-align: top;
        font-size: 1rem;
        display: inline-block;
        width: calc(100% - 16px);
        position: relative;
        margin-left: 8px;
        margin-right: 8px;

        .only-when-in-search {
            display: none;
        }
        &.half,
        &.third {
            width: 100%;
        }

        &.autofill {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                margin-top: 31px;
                width: 16px;
                height: 16px;
                @include svg($icon-lock, $inactive, 1em);
            }
        }

        &.valuta,
        &.percentage {
            &:before {
                content: var(--currency);
                top: 0;
                right: 0;
                display: block;
                position: absolute;
                color: $placeholder;
                margin-top: 28px;
            }

            input[type='text'],
            input[type='number'] {
                text-align: right;
                padding-right: 25px;
            }
        }

        &.percentage {
            &:before {
                content: '%';
            }
        }

        &.budget {
            label,
            input:placeholder-shown ~ label,
            input:focus ~ label,
            input:not(:placeholder-shown) ~ label,
            input:disabled ~ label {
                top: 31px;
                left: 0;
                font-size: 1rem;
                &:not(.normal) {
                    font-size: 1rem;
                    font-weight: 700;
                    font-style: normal;
                    color: $black;
                }
            }
        }

        &.readonly {
            input {
                color: $inactive;
                border-bottom: 2px dotted $border;
            }
            label {
                opacity: 0.5;
            }
            .form-focus {
                &:after,
                &:before {
                    background-color: transparent;
                }
            }
        }

        input ~ label,
        input:disabled ~ label,
        textarea ~ label,
        textarea:disabled ~ label,
        select ~ label,
        select:valid ~ label {
            top: 8px;
            font-size: 12px;
            font-style: normal;
            color: $placeholder;
            position: absolute;
            z-index: 1;
            left: 0;
            font-weight: 300;
            @include transition(0.5s);
        }

        input:placeholder-shown:not(:focus) ~ label,
        select:placeholder-shown:not(:focus) ~ label,
        textarea:placeholder-shown:not(:focus) ~ label,
        select ~ label {
            position: absolute;
            z-index: 1;
            top: 31px;
            left: 0;
            font-size: 1rem;
            font-weight: 300;
            font-style: italic;
            color: $placeholder;
            @include transition(0.5s);
        }

        input:not([type='radio']),
        textarea,
        select {
            position: relative;
            z-index: 2;
            width: 100%;
            color: $black;
            background-color: transparent;
            font-size: 1rem;
            display: block;
            padding: 30px 0 8px;
            border-bottom: 2px solid $border;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            @include transition(0.5s);

            &::-webkit-input-placeholder,
            &:-ms-input-placeholder,
            &:-ms-input-placeholder {
                color: $content;
            }

            &:focus,
            &:not(:placeholder-shown) {
                & ~ .form-focus {
                    &:before,
                    &:after {
                        width: 50%;
                    }
                }
            }

            &:required ~ label::after {
                content: ' *';
                color: $red;
            }

            &:disabled::placeholder {
                color: $inactive;
            }
        }

        input[type='date'] {
            text-transform: uppercase;
            padding: 30px 0 2px;
        }

        textarea {
            position: relative;
            overflow: auto;
            resize: none;
            padding-top: 0;
            margin-top: 30px;
            max-height: 250px;
            font-family: inherit;

            &:focus {
            }
        }

        select {
            border-radius: 0;
            padding: 30px 0 10px;
            appearance: none;
            -webkit-appearance: none;
            background-image: inline-color-svg($icon-arrow-down, $primary-color);
            background-size: 14px;
            background-repeat: no-repeat;
            background-position: right 75%;
            object-fit: contain;
        }

        //input:disabled ~ label,

        .form-focus {
            position: relative;
            display: block;
            width: 100%;

            &:before,
            &:after {
                content: '';
                height: 2px;
                width: 0;
                bottom: 0;
                position: absolute;
                z-index: 3;
                background-color: $primary-color;
                @include transition(0.3s);
            }

            &:before {
                left: 50%;
            }

            &:after {
                right: 50%;
            }
        }

        .form-error {
            font-size: 0.8rem;
            color: $red;
            margin-top: 8px;
            opacity: 0;
            visibility: hidden;
            display: inline-block;
            @include transition(0.5s);

            &.active {
                visibility: visible;
                opacity: 1;
            }

            &.configuration {
                position: absolute;
                right: 0;
                bottom: 0;
                color: $inactive;
            }
        }

        .select-component {
            width: 100%;
            cursor: pointer;
            position: relative;
            border: none;
            border-bottom: 2px solid $primary-color;
            border-radius: 0;
            padding: 30px 0 10px;
            appearance: none;
            color: #333333;
            -webkit-appearance: none;
            background-image: inline-color-svg($icon-arrow-down, $primary-color);
            background-size: 14px;
            background-repeat: no-repeat;
            background-position: right 75%;
            object-fit: contain;
            &:after {
                background: none;
            }

            .option-component-container {
                top: 62px;
                .option-component {
                }
            }
        }

        &.basic-select {
            .select-component {
                &.checkbox {
                    top: auto;
                    height: auto;
                    &:hover,
                    &.expanded {
                        background-color: transparent;
                    }
                }
            }
        }

        &.force-full {
            width: 100% !important;
        }

        .select-box-label {
            position: absolute;
        }
    }

    a.form-item {
        cursor: auto;
    }

    //
    // Buttons
    //
    button:not(.btn) {
        cursor: pointer;
        display: table;
        padding: 12px 15px;
        height: 50px;
        font-size: 1rem;
        color: $white;
        background-color: $primary-color;
        border-radius: 3px;
        margin: 0 auto;
        float: none;
        clear: both;
        @include transition(0.5s);

        &:disabled {
            background-color: $inactive;
        }

        &.application-next {
            margin: 0;
            //float: right;
            bottom: 0;
        }

        &.boxed {
            color: $black;
            font-weight: 300;
            border: 1px solid $border;
            background-color: transparent;
            &:hover {
                color: $white;
            }
        }

        &.btn-right {
            margin: 0 5px 0 0;
        }
    }

    //
    // Checkbox
    //

    //
    // Checkbox
    //
    .checkbox {
        position: relative;
        top: 10px;
        margin-bottom: 1rem;

        input[type='checkbox'] {
            display: none;
        }

        label {
            font-weight: 300;
            width: 100%;
            padding: 10px 0;
            display: inline-block;

            &:before {
                content: '';
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 3px;
                border: 1px solid $border;
                background-color: $white;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                vertical-align: middle;
                margin-right: 20px;
                cursor: pointer;
            }

            &:nth-child(3) {
                &:before {
                    border: transparent;
                }
                z-index: -1;
            }
            &.only-icon {
                width: 32px;
            }
        }

        input[type='checkbox']:checked + label:before {
            @include svg($icon-check, $green, 18px);
        }

        input[type='checkbox']:checked + label {
            color: $black;
        }

        &.search {
            input[type='checkbox'] + label {
                font-size: 1rem;
            }
            input[type='checkbox']:checked + label:before {
                @include svg($icon-check, $white, 18px);
                background-color: $green;
                background-color: var(--success-background-color);
            }
        }
    }

    .readonly {
        .checkbox {
            label {
                &:before {
                    background-color: $alt-border;
                }
            }
        }
    }

    .checkbox-wrapper {
        background-color: $alt-border;
        border: 1px solid $border;
        margin: 50px 0 30px;
        @include transition(0.5s);

        &.checked {
            background-color: $white;
            border: none;
            @include shadow(0.3);

            label {
                border-bottom: 1px solid $border;
            }
        }

        .checkbox {
            input[type='checkbox'] {
                display: none;
            }

            label {
                font-weight: 300;
                width: 100%;
                padding: 30px 30px;
                display: inline-block;

                &:before {
                    content: '';
                    min-width: 30px;
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    border-radius: 3px;
                    border: 1px solid $border;
                    background-color: $white;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    vertical-align: middle;
                    margin-right: 20px;
                    cursor: pointer;
                }
            }

            input[type='checkbox']:checked + label:before {
                @include svg($icon-check, $green, 18px);
            }
        }

        .external-investments-wrapper {
            padding: 20px;

            label {
                border-bottom: none;
            }
        }
    }
    &.payment-container {
        font-size: 1rem;
        > div {
            @include flex(space-between);
            align-items: center;
            margin-bottom: 15px;
            margin-top: 15px;
            font-size: 1rem;
            > div {
                @include flex(flex-start);
            }
            button {
                &.button {
                    margin: 0 10px 0 0;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &.smaller {
                    height: 40px;
                }
                &.add {
                    line-height: 0.5em;
                    width: 40px;
                    font-size: 2em;
                }
            }
        }
        .select-box {
            padding-bottom: 8px;
            height: 62px;
        }

        table {
            @include table;
            tr {
                td {
                    padding: 0 10px;
                    min-width: 180px;
                    &:last-child {
                        min-width: 40px;
                        width: 40px;
                        padding-right: 15px;
                    }
                    &:first-child {
                        padding-left: 15px;
                    }
                }
                th {
                    padding-top: 5px;
                }
                &.results-row {
                    td {
                        padding: 4px;
                        padding-left: 15px;
                        text-align: right;
                        div {
                            @include flex(space-between);
                        }
                    }
                }
            }

            &.view-only {
                margin-top: 20px;
                tr {
                    td {
                        padding: 10px;
                    }
                }
            }
            &.payment-plan {
                tr td,
                tr th {
                    width: 25%;
                }
            }
            &.payment {
                tr td,
                tr th {
                    width: 20%;
                }
            }
        }
        margin-bottom: 60px;

        a {
            &.form-item {
                &.flex {
                    > div {
                        @include flex(space-between);
                        align-items: center;
                    }
                }
            }
        }
    }

    .select-box-label {
        position: absolute;
    }
}

@media screen and (min-width: 768px) {
    form,
    .cdk-drag-preview {
        .form-item {
            &-container {
                @include flex(space-between);
            }

            &.half {
                width: calc(50% - 16px);

                &.half-last {
                    margin-left: 30px;
                }
            }

            &.half.half-last + .form-item {
                clear: both;
            }

            &.third {
                width: calc(#{100% / 3} - #{3 * 16px});

                &.third-last {
                    margin-right: 0;
                }
            }

            &.third.third-last + .form-item {
                clear: both;
            }
        }

        //
        // Upload
        //
        .upload {
            cursor: pointer;
            .upload-head,
            .upload-item {
                .upload-filename {
                    width: 75%;
                    padding-left: 30px;
                }

                .upload-size {
                    width: 15%;
                }

                .upload-delete {
                    width: 10%;
                    padding-right: 30px;
                }

                &.thread {
                    display: flex;
                    align-items: center;

                    .upload-filename {
                        width: 75%;
                        padding-left: 15px;
                        &.downloadable {
                            cursor: pointer;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .upload-size {
                        width: 20%;
                    }

                    .upload-delete {
                        width: 10%;
                        padding-right: 15px;
                        .delete-file {
                            position: relative;
                            float: right;
                            width: 30px;
                            height: 30px;
                            background-color: $white;
                            border: 1px solid $border;
                            border-radius: 3px;

                            .icon-delete-file {
                                width: 20px;
                                height: 20px;
                                @include center(-50%, -50%);
                                display: block;
                                @include svg($icon-trash, $black, 15px);
                                background-position: center center;
                            }

                            &:hover {
                                background-color: $primary-color-hover;
                                .icon-delete-file {
                                    @include svg($icon-trash, $white, 15px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.applicant {
    form {
        .form-item {
            select {
                border-bottom: 2px solid $applicant-primary-color-hover;
                background-image: inline-color-svg($icon-arrow-down, $applicant-primary-color-hover);
            }
        }
    }
}

// radio buttons
.radio {
    display: block;
    width: 100%;
    word-break: break-word;
    line-height: 1.4;
    cursor: pointer;

    input[type='radio'] {
        margin-right: 8px;
    }
}

//
// Upload
//
.upload {
    width: 100%;
    display: table;
    margin-bottom: 30px;
    @include shadow(0.3);

    .upload-head,
    .upload-item {
        float: none;
        width: 100%;
        display: inline-block;
        position: relative;

        @include flex(space-between);

        .upload-filename,
        .upload-size,
        .upload-delete {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .upload-filename {
            width: 60%;
            padding-left: 10px;
            overflow-x: hidden;
        }

        .upload-size {
            width: 22%;
        }

        .upload-delete {
            width: 18%;
            padding-right: 10px;
            text-align: right;

            .delete-file {
                position: relative;
                float: right;
                height: 20px;

                .icon-delete-file {
                    width: 16px;
                    height: 16px;
                    display: block;
                    @include svg($icon-cross, $placeholder, 1rem);
                }

                &:hover {
                    .icon-delete-file {
                        @include svg($icon-cross, $red-hover, 1rem);
                    }
                }
            }
        }

        .upload-process {
            width: 100%;
            height: 2px;
            background-color: $green;
            background-color: var(--success-background-color);
            display: block;
            position: absolute;
            bottom: 0;
        }
    }

    .upload-head {
        .upload-filename,
        .upload-size,
        .upload-delete {
            white-space: nowrap;
            color: $black;
        }
    }

    .upload-item {
        .upload-filename,
        .upload-size,
        .upload-delete {
            font-weight: 300;
        }

        &.upload-error {
            .upload-process {
                background-color: $red;
            }
        }
    }

    .upload-dragdrop {
        background-color: $alt-border;
        padding: 50px 30px;
        position: relative;
        border-top: 1px solid $border;
        cursor: pointer;

        .upload-dragdrop-icon {
            width: 100px;
            height: 100px;
            display: block;
            opacity: 0.06;
            @include svg($icon-upload, $black, 100px);
            @include center(-50%, -50%);
        }

        p {
            padding: 0;
            font-weight: bold;
            text-align: center;
        }
    }
    .on-drag-over {
        .upload-dragdrop {
            background-color: $inactive;
        }
    }
}
