.datepicker-container {
    input {
        width: 100% !important;
        cursor: pointer;
        border: none !important;
        background-image: inline-color-svg($icon-arrow-down, $black);
        background-size: 10px;
        background-repeat: no-repeat;
        object-fit: contain;
        background-position: right center;
        bottom: 20px !important;
        position: absolute !important;
        padding: 18px !important;
        font-size: 0 !important;
    }
    .calendar-container {
        top: 50px !important;
    }
    height: 0px !important;
    display: inherit !important;
    position: inherit !important;
    span {
        font-size: 12px;
    }
}

.datepicker-container {
    span {
        font-size: 14px;
    }
}

.ngx-datepicker-position-bottom-right {
    top: -21px !important;
    width: 100% !important;
    min-width: 310px;
}

span {
    &.day-unit {
        width: 40px !important;
        margin: 0 calc(100% / 14 - 20px);
    }
}

.ngx-datepicker-calendar-container {
    z-index: 10;
}

.pwa-datepicker {
    .datepicker-container {
        input {
            background-image: inline-color-svg($icon-arrow-down, $pwa-orange);
            background-size: 16px;
            background-color: transparent;
            top: -8px !important;
        }
        .calendar-container {
            top: 35px !important;
            left: -16px !important;
        }
    }
}
