$header-zindex: 500;

//
// Header
//
header {
    .header {
        nav {
            .close-btn {
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 10;
            }
            &.desktop {
                ul {
                    li {
                        form {
                            background: white;
                            position: fixed !important;
                            padding: 25px 20px;
                            @include center(-50%, -50%);
                            width: 400px;
                            h2 {
                                text-align: left;
                                width: 100%;
                                padding-right: 50px;
                                margin-bottom: 30px;
                            }

                            button {
                                margin: 0;
                                display: inline-block;
                            }

                            a {
                                //float: left;
                            }
                        }
                    }
                }
                .actions-container {
                    align-items: center;
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                }
            }
            .center {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
    a {
        &.language {
            display: inline-block;
            color: $content;
            & > .active {
                color: $black;
                font-weight: bold;
            }
        }
    }

    .header {
        width: 100%;
        height: 70px;
        display: table;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $white;
        z-index: 1;
        @include transition(0.3s);

        .container {
            height: 70px;
            @include flex(space-between);
            align-items: center;
        }

        .logo {
            width: 105px;
            height: 32px;
            display: block;
            @include logo(105px);
            @include transition(0.5s);
        }

        .button-mobile {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            border: 1px solid $border;
            position: relative;
            display: block;
            float: right;

            &.landing-page {
                div {
                    @include svg($icon-user, $black, 20px);
                }
            }

            div {
                width: 20px;
                height: 20px;
                @include svg($icon-burger, $black, 20px);
                @include center(-50%, -50%);
            }
        }
        nav {
            &.desktop {
                ul {
                    li {
                        a {
                            &.menu-icon {
                                &:hover {
                                    background-color: $primary-color-hover;
                                }
                            }
                            &:hover {
                                .search {
                                    @include svg($icon-search, $white, 20px);
                                }
                                .notifications {
                                    @include svg($icon-bell, $black, 20px);
                                }
                                .correspondence {
                                    @include svg($icon-mail, $white, 20px);
                                }
                                .settings {
                                    @include svg($icon-settings, $white, 20px);
                                }
                            }
                        }
                    }
                }
            }
        }

        .internal-container {
            @include flex(space-between);
            align-items: center;
        }
    }

    nav {
        &.mobile {
            width: 100%;
            height: calc(100vh - 70px);
            position: fixed;
            top: 100vh;
            left: 0;
            opacity: 0;
            visibility: hidden;
            background-color: $white;
            z-index: $header-zindex;
            @include transition(0.5s);

            &.active {
                top: 70px;
                visibility: visible;
                opacity: 1;
                display: block;
            }

            ul {
                margin-top: 30px;

                &:first-child {
                    margin-top: 0;
                }

                h3 {
                    text-align: center;
                    padding: 15px 0;
                }

                li {
                    &:first-of-type {
                        a {
                            border-top: 1px solid $border;
                        }
                    }

                    a {
                        width: 100%;
                        display: block;
                        padding: 20px 0;
                        text-align: center;
                        border-bottom: 1px solid $border;
                        color: $black;
                    }
                }
            }

            #login {
                width: 100%;
                padding: 30px 20px;

                h3 {
                    text-align: center;
                }

                button {
                    margin-top: 30px;
                }
            }

            #recovery {
                width: 100%;
                padding: 30px 20px;

                h3 {
                    text-align: center;
                }

                button {
                    margin-top: 30px;
                }
            }
        }

        &.desktop {
            display: none;
        }

        &.recover-password {
            ul {
                @include flex(flex-end);
                li {
                    width: 40px;
                }
            }
        }
    }

    &.header-fixed {
        .header {
            z-index: $header-zindex;
            position: fixed;
            background-color: $white;
            @include shadow(0.3);

            .logo {
                width: 200px;
                @include logo(contain);
                background-position: center left;
            }

            .button-mobile {
                div {
                    @include svg($icon-burger, $black, 20px);
                }

                &.landing-page {
                    div {
                        @include svg($icon-user, $black, 20px);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $tab) {
    header {
        .header {
            .button-mobile {
                display: none;
            }

            nav {
                &.mobile {
                    &.active {
                        display: none;
                    }
                }
                &.desktop {
                    display: inline-block;

                    ul {
                        li {
                            display: inline-block;
                            margin-right: 30px;
                            vertical-align: middle;

                            .active {
                                font-weight: bold;
                            }

                            &:last-child {
                                margin-right: 0;
                            }

                            &.icon-item {
                                margin-right: 5px;
                            }

                            a {
                                &.menu-icon {
                                    width: 50px;
                                    height: 50px;
                                    display: block;
                                    position: relative;
                                    border: 1px solid $border;
                                    border-radius: 3px;
                                    &.smaller {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }

                                &.button {
                                    &.smaller {
                                        padding: 7px 10px;
                                    }
                                }

                                .search,
                                .notifications,
                                .correspondence,
                                .settings {
                                    width: 20px;
                                    height: 20px;
                                    @include center(-50%, -50%);
                                }
                                .search {
                                    @include svg($icon-search, $black, 20px);
                                }
                                .notifications {
                                    @include svg($icon-bell, $black, 20px);
                                }
                                .correspondence {
                                    @include svg($icon-mail, $black, 20px);
                                }
                                .settings {
                                    @include svg($icon-settings, $black, 20px);
                                }
                            }
                        }
                    }
                }
            }

            &.original-header {
                @include transition(0s);
                height: initial;
                .container {
                    height: initial;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    align-items: flex-end;
                    &-lading-page {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        button {
                            flex-shrink: 0;
                        }
                    }
                }
                .logo {
                    @include transition(0s);
                    width: 190px;
                    height: 110px;
                    @include logo(contain);
                }
                a {
                    :not(.btn) {
                        color: #333333;
                    }
                    &.language {
                        display: inline-block;
                        color: $content;
                        & > .active {
                            color: $black;
                            font-weight: bold;
                        }
                    }
                }

                background-color: #ffffff;
                box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.3);
                z-index: $header-zindex;
            }
        }

        &.header-fixed {
            .header {
                &.original-header {
                    .logo {
                        width: 190px;
                        height: 50px;
                        @include logo(contain);
                    }
                }
            }
            nav {
                &.desktop {
                    align-items: center;
                    display: flex;
                }
            }
            .internal-container {
                &.applicant {
                    width: initial;
                    li {
                        margin-right: 0;
                        > * {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
        &.header-sticky {
            .internal-container {
                flex-flow: row wrap;
                justify-content: flex-end;
                nav {
                    width: 100%;
                    .language {
                        padding: 18px 10px;
                    }
                }

                &.applicant {
                    width: initial;
                    li {
                        margin-right: 0;
                    }
                    .language {
                        padding: 18px 10px;
                    }
                }
            }
        }
    }
}
