.record-filters-container {
    margin-right: 10px;
    margin-bottom: 10px;

    a {
        &.close-btn {
            &.smaller {
                width: 20px;
                margin-right: 10px;
                height: 20px;
                top: 10px;
                position: relative;
                border-radius: 100%;
                border: none;
                background-color: $inactive;
                margin-left: 16px;

                .icon-close {
                    @include svg($icon-cross, $white, 10px);
                    width: 20px;
                    height: 20px;
                }
                &:hover {
                    background-color: $black;
                    .icon-close {
                        @include svg($icon-cross, $white, 10px);
                    }
                }
            }

            &.lower {
                margin-left: 5px;
                top: 10px;
            }
        }
    }

    form {
        .form-item {
            .only-when-in-search {
                display: block;
            }

            .only-when-not-in-search {
                display: none;
            }

            float: none;
            margin-bottom: 0;
            &.half {
                width: 100%;
            }
            &.between {
                width: 55%;
                display: inline-block;
                &.first {
                    width: 45%;
                }

                &.second {
                    label {
                        padding-left: 5px;
                    }
                    input {
                        padding-left: 5px;
                    }
                }

                &.even {
                    label {
                        padding-left: 5px;
                    }
                    input {
                        padding-left: 5px;
                    }
                    &.first {
                        width: 50%;
                    }
                    &.second {
                        width: 50%;
                    }
                }
            }
            .form-error {
                display: none;
            }

            .checkbox {
                top: 0;
                height: 60px;
                label {
                    padding: 18px 0 2px 0;
                    &:before {
                        margin-right: 10px;
                    }
                }
            }

            .ngx-datepicker-position-bottom-right {
                width: 300px !important;
                border: none !important;
                top: 0 !important;
            }

            .ngx-datepicker-input {
                @include svg($icon-calendar, $primary-color, 15px);
                position: absolute !important;
                background-position: 100% 75%;

                bottom: 0px !important;
                height: 60px !important;
            }

            div {
                &.select-box {
                    padding: 26px 3px 12px;

                    &:after {
                        right: 15px;
                        height: 100%;
                        top: 5px;
                    }

                    &.open {
                        &:after {
                            z-index: 1000;
                            @include rotate(180deg);
                        }
                    }

                    .dropdown-container {
                        top: 0px;
                        max-height: 300px;
                        overflow: auto;
                        .dropdown-element {
                            padding: 26px 3px 12px;

                            &:hover {
                                background-color: $alt-white;
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
        > div {
            > span {
                display: block;
                &:first-child {
                }
            }
            width: 250px;
            &.double {
                width: 375px;
                .form-item {
                }
            }
        }
    }

    &.boolean {
        form {
            > div {
                width: inherit;
                .form-item {
                    label {
                        position: relative;
                        margin-right: 15px;
                        padding: 14px 0 2px 0;
                        font-size: 1em;
                    }
                }
            }
        }
    }

    &.custom {
        margin-top: 10px;
        border: 1px #e7e9f2 solid;
        padding: 3px;
        li {
            input[type='text'],
            input[type='number'] {
                padding-right: 25px;
            }

            textarea {
                margin-top: 0 !important;
                resize: none;
                height: 2em !important;
                overflow: hidden;
            }

            label {
                font-size: 1.1em;
                color: $placeholder;
                padding: 10px;
                display: inline-block;
            }
            div {
                &.select-box {
                    position: absolute;
                    right: 40px;
                    top: 8px;
                    padding: 10px 0 5px;
                    min-width: 200px;
                    display: block;
                    .dropdown-container {
                        z-index: 25;
                        top: 36px;
                        position: absolute;
                        .dropdown-element {
                            display: block;
                        }
                    }
                }
            }

            abbr {
                .form-item {
                    margin-bottom: 0;
                }
                label {
                    display: none;
                }
                .form-error {
                    display: none;
                }
                div {
                    display: inline-block;
                    position: relative;
                    &.select-box {
                        top: -5px;
                        right: 0;
                        position: relative;
                    }
                }

                .ngx-datepicker-container {
                    position: absolute;
                    top: 60px;
                    right: 20px;
                }
            }

            position: relative;
            a {
                @include svg($icon-cross, $red, 20px);
                position: absolute;
                content: '';
                width: 20px;
                height: 100%;
                right: 5px;
                top: 0;
                cursor: pointer;
                transition: 0.5s;
                &:hover {
                    @include svg($icon-cross, $black, 20px);
                }
            }
            width: 100%;
            padding: 5px;
            border: 1px #e7e9f2 solid;

            &.header {
                cursor: pointer;
                label {
                    color: $black;
                }

                a {
                    top: 10px;
                    right: 10px;
                    width: 0;
                    height: 0;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                    border-left: 15px solid #e7e9f2;
                    background-image: none;
                }

                &.active {
                    a {
                        top: 15px;
                        transform: rotate(90deg);
                    }
                }
            }

            &.disabled {
                position: absolute;
                z-index: -1;
                pointer-events: none;
                visibility: hidden;
                opacity: 0;
                height: 0;
            }
        }

        select {
            position: absolute;
            right: 40px;
            top: 8px;
        }
    }
}
