.badge {
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 12px;
    color: $white;
    background: transparent;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    &--error {
        background: $red;
    }

    &--fonda {
        background: $fonda-blue;
    }

    &--success {
        color: $black;
        background: $green;
        color: var(--success-color);
        background: var(--success-background-color);
    }

    &--secondary {
        color: $white;
        background: $black;
    }

    .btn--icon & {
        position: absolute;
        top: -5px;
        right: -5px;
    }
}
